import React from "react";

function Diwali2024() {
  return (
    <div className="iframe-container">
      <iframe
        src={`https://docs.google.com/gview?embedded=true&url=https://heartyyfresh-prod.s3.us-west-1.amazonaws.com/promotion_banner/Fremont_Apni_Mandi_Weekend_Sale_11th_oct__compressed.pdf`}
      />
    </div>
  );
}

export default Diwali2024;
